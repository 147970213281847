export const Skills = [
  {
    value: 90,
    title: 'Communication',
  },
  {
    value: 90,
    title: 'Flexibility'
  },
  {
    value: 60,
    title: 'Leadership'
  },
  {
    value: 95,
    title: 'Speed'
  },
  {
    value: 98,
    title: 'Development'
  }
]
